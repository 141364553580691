import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, Subject } from "rxjs";

import { HeaderInfo } from "../../models/header-info.model";


@Injectable({
  providedIn: "root",
})
export class HeaderInfoService {
  defaultInfo: HeaderInfo = {
    isBackground: true,
    title: "e-Perearstikeskus",
    isLeftIcon: true,
    isLeftIconDesktop: true,
    leftIconName: "Home",
  };

  headerInfo = new BehaviorSubject<HeaderInfo>(this.defaultInfo);
  headerShrink = new Subject<boolean>();
  scrollOffset: number;


  private toggleNotificationsSubject = new Subject<void>();

  getToggleNotificationsObservable() {
    return this.toggleNotificationsSubject.asObservable();
  }

  toggleNotifications() {
    this.toggleNotificationsSubject.next();
  }

  constructor() {
  }

  setHeaderInfo(headerInfo: HeaderInfo) {
    const mergedHeaderInfo = Object.assign({}, this.defaultInfo, headerInfo);
    this.headerInfo.next(mergedHeaderInfo);
  }

  setHeaderShrink(value) {
    this.headerShrink.next(value);
  }

  needToShrinkHeader(): Observable<boolean> {
    return this.headerShrink.asObservable();
  }
}
